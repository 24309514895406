import {Accordion} from "../../../../components/accordion";
import illustration from "../../../../assets/svg/faq.svg";
import {useState} from "react";

const faqData = [
  {
    title:
      "Apa perbedaan konseling dengan konselor dan psikolog?",
    description:
      `Perbedaan utama dari konseling psikolog dan konselor adalah ruang lingkup isu dan penanganan yang bisa dilakukan.

Masalah yang ditangani konselor bersifat normal-bermasalah (kebiasaan menunda, manajemen waktu, dll). Sedangkan, psikolog berwenang untuk menangani masalah yang bersifat klinis (gangguan kecemasan, keinginan bunuh diri, adiksi, dll)

Seorang konselor berperan mendengarkan keluhan klien atau memberi pertolongan pertama dalam bentuk saran praktis. Sedangkan Psikolog, selain mendengarkan keluhan dan memberi pertolongan pertama, berwenang memberikan diagnosis dan psikoterapi yang dapat membantumu pulih dari masalah utamamu.`,
  },
  {
    title:
      "Waktu Pendaftaran Konseling. Apakah saya dapat langsung mendaftar konseling untuk meminta konseling di hari yang sama?",
    description:
      "Ya, Anda dapat membuat janji dengan psikolog Mudita hingga 24 jam sebelum sesi selama jadwal psikolog tersedia.",
  },
  {
    title:
      "Cara melakukan janji konseling. Bagaimana prosedur membuat janji konseling dengan psikolog Mudita?",
    description:
      "Untuk membuat janji dengan Psikolog Mudita, silakan pilih Psikolog dengan spesialisasi masalah dan rentang pengalaman sesuai preferensi Anda. Anda juga dapat menggunakan fitur pencarian dan filter. \n\nKlik “Jadwalkan Sesi” dan Anda akan diarahkan ke WhatsApp kami di +62 852-1949-8199. Selanjutnya, kami akan menanyakan waktu yang Anda inginkan dan memberikan tautan formulir pendaftaran untuk Anda isi. Kemudian, Anda akan langsung diberikan beberapa rekomendasi psikolog yang sesuai dengan masalah dan jadwal Anda.",
  },
  {
    title:
      "Hal yang didapat dari satu konseling. Apa saja yang saya dapatkan dari satu konseling di Mudita?",
    description:
      "Anda dapat meminta pesan pasca konseling kepada Praktisi yang menangani",
  },
  {
    title: "Durasi Telekonseling. Berapa lama konseling di Mudita?",
    description:
      "Dalam satu kali sesi, Anda bisa melakukan konseling selama 60-70 menit. Pada menit 71, Anda akan dikenakan biaya tambahan.",
  },
  {
    title:
      "Biaya layanan. Berapa biaya yang harus saya keluarkan untuk satu kali konseling di Mudita?",
    description:
      "Untuk biaya konsultasi di Mudita beragam, mulai dari Rp 175.000 hingga Rp 349.000, Anda dapat mendapatkan penanganan dari konselor, psikolog, hingga psikolog senior.",
  },
  {
    title:
      "Perbedaan harga layanan. Apa yang membedakan biaya layanan konseling di Mudita?",
    description:
      "Perbedaannya terletak pada rentang pengalaman dari praktisi Mudita.",
  },
  {
    title:
      "Jumlah sesi. Apakah yang terjadi jika di tengah sesi konseling internetku terputus?",
    description:
      "Jawabannya adalah tergantung dari kondisi setiap individu. Namun, Anda bisa menyampaikan kebutuhan dan kondisi Anda pada psikolog Mudita.",
  },
  {
    title:
      "Layanan Luring. Apakah Mudita memiliki layanan konseling luring/offline?",
    description:
      "Untuk saat ini, Mudita fokus memberikan layanan konseling daring melalui website. Kendati demikian, kami menyediakan layanan on-site psychologist sesuai dengan kerjasama dengan universitas dan perusahaan.",
  },
];

export const FAQ = () => {
  const [showMore, setShowMore] = useState<boolean>(false);

  const firstArray = faqData.slice(0, 4);
  const restArray = faqData.slice(4);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="md:py-20 py-14 w-full flex flex-col md:flex-row px-12 md:px-32">
      <div className="md:w-1/2 m-auto">
        <img
          src={illustration}
          alt="illustration"
          className="md:h-[35em] md:mx-auto md:pt-4 mb-0 md:mb-0"
        />
      </div>
      <div className="md:w-1/2 m-auto md:m-0">
        <span className="font-bold text-2xl md:text-3xl xl:text-5xl text-primary-text whitespace-pre-wrap pb-6 block">
          <span className="text-orange">Pertanyaan </span>
          Sering Diajukan
        </span>
        {firstArray.map((data) => (
          <Accordion {...data} key={data.title}/>
        ))}
        {showMore && restArray.map((data) => <Accordion {...data} />)}
        <p
          className="font-semibold text-primary text-sm cursor-pointer text-right"
          onClick={handleShowMore}
        >
          {/* {!showMore && "Lihat Pertanyaan Lain"} */}
        </p>
      </div>
    </div>
  );
};
