import { Seperator } from "../../../../components/seperator";
import { Images } from "../../../../assets/png";
import { LineGraph } from "../../../../components/line_graph";

interface Props {
  image: any;
  title: any;
  description: any;
  className: string;
}

const ServiceCard = ({ image, title, description, className }: Props) => {
  return (
    <div className={className}>
      <img src={image} alt="Interactive Seminar" width="70" className="my-2" />
      <div className="flex flex-col gap-[10px]">
        <h1 className="font-bold text-base sm:text-[18px]">{title}</h1>
        <p className="text-navbar-accent text-[14px] sm:text-base max-w-[250px]">
          {description}
        </p>
      </div>
    </div>
  );
};

const serviceData = [
  {
    image: Images.Icons.call,
    title: "Hubungi Kami",
    description: "Hubungi tim sales kami di sini dan konsultasikan kebutuhan",
    style:
      "xl:absolute xl:-bottom-[0px] xl:left-[10px] min-[1450px]:-bottom-[20px] 2xl:-bottom-[40px] 2xl:left-[0px] w-80 xl:flex xl:flex-col-reverse",
  },
  {
    image: Images.Icons.copy,
    title: "Check Up & Rekomendasi",
    description: "Dapatkan rekomendasi dan analisis company checkup",
    style:
      "xl:absolute xl:top-[270px] xl:left-[330px] min-[1450px]:left-[360px] min-[1450px]:top-[300px] 2xl:top-[320px] 2xl:left-[350px] w-80",
  },
  {
    image: Images.Icons.heart_pulse,
    title: "Pemberian Layanan",
    description:
      "Layanan terpersonalisasi diberikan sebagai solusi masalah individual dan interpersonal",
    style:
      "xl:absolute xl:bottom-[130px] xl:left-[570px] min-[1450px]:left-[670px] min-[1450px]:bottom-[100px] 2xl:bottom-[90px] 2xl:left-[680px] w-80 xl:flex xl:flex-col-reverse",
  },
  {
    image: Images.Icons.graph,
    title: "Lihat Hasilnya",
    description:
      "Dapatkan laporan secara berkala terkait peningkatan di perusahaan Anda",
    style:
      "xl:absolute xl:top-[120px] xl:left-[850px] min-[1450px]:top-[140px] min-[1450px]:left-[980px] 2xl:top-[150px] 2xl:left-[1050px] w-80",
  },
];

export const CompanySolution = () => {
  return (
    <div className="mt-16 max-w-screen mx-[7%] xl:mx-32">
      <p className="title mb-8">
        <span className="font-bold text-[32px] md:text-[40px] xl:text-[52px] text-primary-text whitespace-pre-wrap">
          Bagaimana Kami
          <span className="text-orange">{"\n"}Membantu </span>
          Anda?
        </span>
      </p>
      <Seperator />
      <div className="xl:relative xl:h-[420px] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols items-center mx-[7%] xl:mx-0 md:mb-36">
        <LineGraph />
        {serviceData.map((data) => (
          <ServiceCard
            image={data.image}
            title={data.title}
            description={data.description}
            className={data.style}
          />
        ))}
      </div>
    </div>
  );
};
