import { useNavigate } from "react-router-dom";

export const Drawer = () => {
  const navigate = useNavigate();
  return (
    <div
      className="absolute bg-white rounded-lg w-[12em] shadow-sm
        top-[104px] z-50"
    >
      <div className="pb-2">
        <div className="p-2 pb-0 flex flex-col gap-3 text-left">
          <p
            className="cursor-pointer select-none text-[14px]"
            rel="noreferrer"
            onClick={() => {
              navigate("/services", { state: { service: "service_1" } });
            }}
          >
            Company Checkup
          </p>
          <p
            className="cursor-pointer select-none text-[14px]"
            rel="noreferrer"
            onClick={() => {
              navigate("/services", { state: { service: "service_2" } });
            }}
          >
            Seminar Interaktif
          </p>
          <p
            className="cursor-pointer select-none text-[14px]"
            rel="noreferrer"
            onClick={() => {
              navigate("/services", { state: { service: "service_3" } });
            }}
          >
            Konseling Kelompok
          </p>
          <p
            className="cursor-pointer select-none text-[14px]"
            rel="noreferrer"
            onClick={() => {
              navigate("/services", { state: { service: "service_4" } });
            }}
          >
            Psikolog Kantor/Kampus
          </p>
          <p
            className="cursor-pointer select-none text-[14px]"
            rel="noreferrer"
            onClick={() => {
              navigate("/services", { state: { service: "service_5" } });
            }}
          >
            One-on-One Coaching
          </p>
          <p className="text-tertiary text-xs text-orange">Segera</p>
          <p className="text-[14px]">Kuota Konseling Daring</p>
          <p className="text-[14px]">Individual Development Program</p>
        </div>
      </div>
    </div>
  );
};
