import { useEffect } from "react";
import { Template } from "../../components/template";
import { Slider } from "./components/slider";
import { PractitionerList } from "./components/practitioner_list";
import { FAQ } from "./components/faq";

export const Counseling = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <Template>
      <div className="grid">
        <Slider />
        <PractitionerList />
        <FAQ />
      </div>
    </Template>
  );
};
