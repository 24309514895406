import { useEffect, useState } from "react";
import { Images } from "../../assets/png";
import { Seperator } from "../../components/seperator";
import { Template } from "../../components/template";
import { useLocation } from "react-router-dom";

interface ServiceObject {
  [key: string]: IService;
}

interface IService {
  header: JSX.Element;
  description: string;
  list_description: string[];
  bottom_description: string;
  image: any;
}

const data: ServiceObject = {
  service_1: {
    header: (
      <span className="text-[32px] md:text-[40px] xl:text-[52px] whitespace-pre-wrap font-bold">
        Mental Health
        <span className=" text-orange"> Check Up </span>
      </span>
    ),
    description:
      "Merasa memiliki masalah namun tidak bisa mengetahui alasannya? Merasa produktivitas karyawan menurun? Dapatkan gambaran umum kondisi kesehatan mental karyawan dan mendeteksi area yang berpotensi mengganggu produktivitas karyawan.",
    list_description: [
      "Dirancang khusus oleh psikolog berdasarkan alat ukur yang sudah diuji reliabilitas dan validitasnya",
      "Durasi 15-20 menit pengukuran, ditutup sesi relaksasi",
      "Laporan akan dikirim dalam dua hari berikut rekomendasi program yang disusun psikolog",
    ],
    bottom_description:
      "Mari mengenal kondisi kesehatan mental kita bersama dengan Mudita!",
    image: Images.service_1,
  },
  service_2: {
    header: (
      <span className="text-[32px] md:text-[40px] xl:text-[52px] text-primary-text whitespace-pre-wrap font-bold">
        <span className=" text-orange">Seminar/Webinar </span>
        Interaktif
      </span>
    ),
    description:
      "Merasa ingin meningkatkan kemampuan atau pengetahuan karyawan mengenai softskill dan kesehatan mental? Merasa bosan dengan cara seminar yang satu arah? Gunakan layanan seminar interaktif dari Mudita!",
    list_description: [
      "Membantu meningkatkan kesadaran kesehatan mental, produktivitas, komunikasi, dan lain-lain.",
      "Peserta tidak hanya mendengarkan pemaparan kesehatan mental, namun juga terlibat langsung di aktivitas! Setiap peserta seminar bisa mendapatkan ilmu sekaligus kemampuan praktis.",
      "Tema seminar dapat diambil dari hasil Mental Health Check Up",
    ],
    bottom_description:
      "Mari berkembang bersama dan buat pembelajaran di perusahaan Anda menjadi insightful dan menyenangkan bersama Mudita!!",
    image: Images.service_2,
  },
  service_3: {
    header: (
      <span className="text-[32px] md:text-[40px] xl:text-[52px] text-primary-text whitespace-pre-wrap font-bold">
        <span className=" text-orange"> Konseling </span>
        Kelompok
      </span>
    ),
    description:
      "Ingin memperbaiki performa suatu kelompok atau menyelesaikan konflik di dalamnya? Merasa sendirian dalam menghadapi masalah Anda? Gunakan layanan konseling kelompok Mudita!",
    list_description: [
      "Dapat digunakan untuk menyasar kelompok tertentu, misalnya yang memiliki engagement kerja rendah atau yang bermasalah mental.",
      "Dapat digunakan untuk resolusi konflik hingga mempererat hubungan antarkaryawan.",
      "Difasilitasi oleh psikolog untuk membagikan masalahnya dan mendapatkan dukungan sosial dari rekan sekitar.",
      "Tema dan peserta konseling kelompok yang disasar dapat diambil dari hasil Mental Health Check Up.",
    ],
    bottom_description:
      "Mari selesaikan konflik di tempat kerja dan rekatkan hubungan sesama karyawan bersama  Mudita!",
    image: Images.service_3,
  },
  service_4: {
    header: (
      <span className="text-[32px] md:text-[40px] xl:text-[52px] text-primary-text whitespace-pre-wrap font-bold">
        <span className=" text-orange">Psikolog On-Site </span>
        Kampus & Kantor
      </span>
    ),
    description:
      "Ingin bertemu psikolog, namun tidak memiliki waktu? Atau ingin menyediakan layanan konseling di kantor, kampus atau sekolah, tetapi tidak ingin repot? Datangkan psikolog Mudita ke kantor, kampus, atau sekolah Anda dengan layanan psikolog on-site!",
    list_description: [
      "Psikolog akan berjaga selama jam kerja untuk membantu karyawan, mahasiswa, atau siswa.",
      "Cukup sediakan ruangan khusus untuk Psikolog memberikan konseling individual secara tatap muka.",
      "Konsultasikan topik apapun: masalah pribadi, keluarga, atau apapun yang dirasa mengganggu keberfungsian sehari-hari.",
    ],
    bottom_description:
      "Mari ciptakan lingkungan aman di kantor, kampus, atau sekolah dan jadi lebih sehat mental bersama Mudita!",
    image: Images.service_4,
  },
  service_5: {
    header: (
      <span className="text-[32px] md:text-[40px] xl:text-[52px] text-primary-text whitespace-pre-wrap font-bold">
        One on One
        <span className=" text-orange"> Coaching </span>
      </span>
    ),
    description:
      "Merasa kesulitan dalam menentukan arah karier? Merasa bingung mengenai tujuan di masa depan? Atau, merasa bingung mengenai kekuatan dan hal yang perlu dikembangkan dalam diri? Konsultasikan langsung ke Psikolog Mudita!",
    list_description: [
      "Diskusi secara langsung dan mendapatkan rekomendasi karier dan rancangan masa depan langsung dari psikolog tersertifikasi.",
      "Kenali potensi yang Anda miliki, kelemahan yang bisa Anda perkuat, serta arahan karier yang sesuai dengan diri Anda.",
      "Dapat diselenggarakan secara partai besar di kantor, sekolah, atau kampus.",
    ],
    bottom_description:
      "Mari mengenal potensi diri untuk menjadi versi diri yang lebih baik bersama dengan Mudita!",
    image: Images.service_5,
  },
};

const other_services = [
  {
    title: "Company Check Up",
    description:
      "Deteksi masalah pada karyawan atau mahasiswa dan dapatkan rekomendasi",
    img: Images.Icons.copy_only,
    onclick: () => console.log("a"),
    active: true,
    value: "service_1",
  },
  {
    title: "Seminar Interaktif",
    description:
      "Dapatkan edukasi untuk menjaga kesehatan mental dan produktivitas",
    img: Images.Icons.microphone_only,
    onclick: () => console.log("a"),
    active: true,
    value: "service_2",
  },
  {
    title: "Konseling Kelompok",
    description:
      "Selesaikan masalah pada kelompok tertentu atau dalam kelompok tertentu.",
    img: Images.Icons.users_only,
    onclick: () => console.log("a"),
    active: true,
    value: "service_3",
  },
  {
    title: "Psikolog Kantor/Kampus",
    description:
      "Datangkan psikolog ke kantor, sekolah, atau kampus untuk konseling individual.",
    img: Images.Icons.dialog_only,
    onclick: () => console.log("a"),
    active: true,
    value: "service_4",
  },
  {
    title: "One on One Coaching",
    description:
      "Pelatihan pribadi terkait produktivitas, kesehatan mental dan karier.",
    img: Images.Icons.lightbulb_only,
    onclick: () => console.log("a"),
    active: true,
    value: "service_5",
  },
  {
    title: "Kuota Konseling Daring",
    description: "SEGERA HADIR",
    img: Images.Icons.rectangle,
    onclick: () => console.log("a"),
    active: false,
    value: "service_6",
  },
];

export const Services = () => {
  const [active, setActive] = useState(data["service_1"]);
  const location = useLocation();

  useEffect(() => {
    if (location.state.service) {
      const service: string = location.state.service;
      setActive(data[service]);
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [active]);

  return (
    <Template>
      <div className="mb-16 max-w-screen mx-[7%] xl:mx-32">
        <div className="flex flex-col-reverse md:flex-row grid-rows-1 gap-4">
          <div className="">
            {active.header}
            <Seperator />
            <div className="my-10">
              <span className=" text-[14px] sm:text-base">
                {active.description}
              </span>
            </div>
            <div className="ml-10">
              {active.list_description.map((item, index) => {
                return (
                  <div key={index} className=" flex flex-row mb-4">
                    <div className=" bg-primary rounded-full w-10 h-10 mr-4 flex justify-center">
                      <span className=" text-white text-caption self-center ">
                        0{index + 1}.
                      </span>
                    </div>
                    <span className=" text-primary-text text-[14px] sm:text-base flex-1">
                      {item}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="mt-10">
              <span className=" text-[16px] sm:text-[18px] text-body-bold">
                {active.bottom_description}
              </span>
            </div>
          </div>
          <div>
            <img
              src={active.image}
              className="w-3/4 md:w-full m-auto aspect-square"
              alt="description"
            />
          </div>
        </div>
        <div className="mt-8 mb-32">
          <button
            className="bg-gradient-custom from-primary-gradient-end to-primary px-[32px] py-[14px] rounded-[26px]"
            onClick={() => window.open("https://wa.me/6285219498199")}
          >
            <div className="text-caption text-white">Hubungi Kami</div>
          </button>
          <button className="sec-button">
            <div className="text-caption text-primary">Unduh Proposal</div>
          </button>
        </div>
        <p className="text-[48px] whitespace-pre-wrap font-bold text-center text-orange">
          Layanan Kami
        </p>
        <div className="flex justify-center mt-4">
          <Seperator />
        </div>
        <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 grid-rows-2 gap-4 mt-10">
          {other_services.map((item, index) => {
            return (
              <div
                key={index}
                className=" col-span-1 bg-white rounded-xl shadow-sm h-80 p-6 flex flex-col"
              >
                <img src={item.img} className=" w-8 h-8 mb-4" alt="logo" />
                <p className="text-body-bold">{item.title}</p>
                {item.active ? (
                  <p className="text-caption mt-4 mb-8 flex-1">
                    {item.description}
                  </p>
                ) : (
                  <p className="text-caption text-orange mt-4 mb-8">
                    {item.description}
                  </p>
                )}
                {item.active ? (
                  <button
                    className=" shadow-sm bg-white rounded-full p-1 self-start flex flex-row justify-center"
                    //@ts-ignore
                    onClick={() => setActive(data[item.value])}
                  >
                    <p className=" text-caption text-primary mx-4 self-center line-clamp-1">
                      SELENGKAPNYA
                    </p>
                    <img
                      src={Images.Icons.arrow}
                      className=" w-6 h-6"
                      alt="arrow"
                    />
                  </button>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </Template>
  );
};
