import { Icons } from "./icons";

export const Images = {
  nav_logo: require("./nav_logo.png"),
  id_flag: require("./id_flag.png"),
  en_flag: require("./en_flag.png"),
  client_list: require("./client_list.png"),
  intro: require("./intro.png"),
  resources: require("./resources.png"),
  reviews: require("./reviews.png"),
  logo: require("./logo.png"),
  logo_only: require("./logo_only.png"),
  under_construction: require("./under_construction.png"),
  curly_left: require("./curly_left.png"),
  curly_right: require("./curly_right.png"),
  separator: require("./separator.png"),
  service_1: require("./service_1.png"),
  service_2: require("./service_2.png"),
  service_3: require("./service_3.png"),
  service_4: require("./service_4.png"),
  service_5: require("./service_5.png"),
  whatsapp: require("./whatsapp.png"),
  Icons: Icons,
};
