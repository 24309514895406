import { Images } from "../../../../assets/png";
import { Seperator } from "../../../../components/seperator";

export const Us = () => {
  return (
    <>
      <div className="mt-16 relative justify-center pb-32 max-w-screen mx-[7%] xl:mx-32 flex flex-col gap-32">
        <div className="flex flex-col sm:flex-row gap-8">
          <div className="sm:w-1/2 m-auto">
            <img
              src={Images.logo}
              className="w-[230px] md:w-[300px] lg:w-[300px] m-auto"
              alt="logo"
            />
          </div>

          <div className="sm:w-1/2 flex flex-col justify-center my-0 ">
            <p className="title mb-8">
              <span className="font-bold text-[32px] md:text-[40px] xl:text-[52px] 2xl:text-primary-text whitespace-pre-wrap">
                <span className=" text-orange">Tentang </span>
                Mudita
              </span>
            </p>
            <Seperator />
            <span className="text-base mt-8">
              Kami adalah penyedia layanan kesehatan mental yang komprehensif
              dan siap menemani di setiap tahapan dari perjalanan kesehatan
              mental Anda. Berangkat dari Bahasa Sansekerta Muditā yang berarti
              “rasa bahagia saat melihat kebahagiaan orang lain”, tekad kami
              adalah satu: Terus membagikan kebahagiaan ke sebanyak-banyaknya
              manusia lewat peningkatan kesejahteraan mental.
            </span>
            <span className="text-base mt-8">
              Berdiri sejak 2023, kami terus mengembangkan layanan kami untuk
              bisa menjawab semua kebutuhan psikologis Anda dan organisasi: baik
              itu preventif, kuratif, maupun promotif. Lewat komunitas kami,
              kami juga membangun ruang aman bagi siapapun untuk berbagi dan
              mendapat dukungan. Karena kami percaya bahwa akses layanan
              kesehatan mental adalah hak semua orang, tanpa terkecuali.
            </span>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-8">
          <div className="sm:w-1/2 flex flex-col">
            <p className="title">
              <span className="font-bold text-[32px] md:text-[40px] xl:text-[52px] text-orange">
                Visi{" "}
              </span>
            </p>
            <span className="text-base mt-8">
              Menyebarkan kebahagiaan melalui peningkatan kesehatan mental dan
              mewujudkan masyarakat yang sejahtera dalam kebersamaan.
            </span>
          </div>
          <div className="sm:w-1/2 flex flex-col">
            <p className="title">
              <span className="font-bold text-[32px] md:text-[40px] xl:text-[52px] text-orange">
                Misi{" "}
              </span>
            </p>
            <span className="text-body-bold whitespace-pre-wrap mt-8">
              01 {"\n"}
              Berbagi Bahagia
            </span>
            <span className="text-base mt-2">
              Membantu menumbuhkan rasa bahagia lewat pemaknaan keseharian yang
              lebih positif dan pengembangan diri dari berbagai aspek.
            </span>
            <span className="text-body-bold whitespace-pre-wrap mt-8">
              02 {"\n"}
              Sejahtera Bersama
            </span>
            <span className="text-base mt-2">
              Memberikan akses kesehatan mental yang setara bagi semua orang dan
              mendorong terjalinnya ikatan sosial demi tercapainya kesejahteraan
              mental bersama yang lebih positif.
            </span>
          </div>
        </div>
      </div>
      <img
        src={Images.logo_only}
        className=" absolute hidden lg:flex left-0 lg:top-[1100px] min-[1156px]:top-[1000px] xl:top-[980px] 2xl:top-[900px] w-1/4"
        alt="flower"
      />
    </>
  );
};
